/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Text, VStack } from "@chakra-ui/react";

interface ChakraTransitionContainerProps {
  isLoading: boolean;
  overlay: React.ReactNode;
  children: React.ReactNode;
  bgColor?: string;
  timeout?: number;
}

export const ChakraTransitionContainer: React.FC<
  ChakraTransitionContainerProps
> = ({
  isLoading,
  overlay,
  children,
  bgColor = "secondary.300",
  timeout = 15000,
}) => {
  const [showOverlay, setShowOverlay] = useState(isLoading);
  const [showContent, setShowContent] = useState(!isLoading);
  const [showStuckMessage, setShowStuckMessage] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [transitionInProgress, setTransitionInProgress] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const forceShowContentTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const stuckTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const lastTransitionTimeRef = useRef<number>(Date.now());

  const clearTimeouts = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (forceShowContentTimeoutRef.current) {
      clearTimeout(forceShowContentTimeoutRef.current);
      forceShowContentTimeoutRef.current = null;
    }
    if (stuckTimeoutRef.current) {
      clearTimeout(stuckTimeoutRef.current);
      stuckTimeoutRef.current = null;
    }
  };

  const handleReload = () => {
    if (isReloading) return;
    setIsReloading(true);
    console.warn("Recargando página por transición atascada");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  // Efecto para manejar el inicio y fin de las transiciones
  useEffect(() => {
    const initialState = isLoading;
    let mounted = true;
    lastTransitionTimeRef.current = Date.now();

    const startTransition = () => {
      if (!mounted) return;
      setTransitionInProgress(true);
      clearTimeouts();
      setShowStuckMessage(false);

      // Solo configuramos el timeout de atasco si realmente estamos en transición
      if (transitionInProgress) {
        stuckTimeoutRef.current = setTimeout(() => {
          if (mounted && transitionInProgress) {
            console.warn("Transición posiblemente atascada");
            setShowStuckMessage(true);
          }
        }, 8000);
      }
    };

    const finishTransition = () => {
      if (!mounted) return;
      setTransitionInProgress(false);
      setShowStuckMessage(false);
      clearTimeouts();
    };

    startTransition();

    if (initialState) {
      setShowOverlay(true);
      timeoutRef.current = setTimeout(() => {
        if (mounted) {
          setShowContent(false);
        }
      }, 100);

      forceShowContentTimeoutRef.current = setTimeout(() => {
        if (mounted) {
          finishTransition();
          setShowOverlay(false);
          setShowContent(true);
        }
      }, timeout);
    } else {
      setShowContent(true);
      timeoutRef.current = setTimeout(() => {
        if (mounted) {
          setShowOverlay(false);
          finishTransition();
        }
      }, 300);
    }

    return () => {
      mounted = false;
      clearTimeouts();
    };
  }, [isLoading, timeout]);

  // Efecto para limpiar el mensaje de atasco cuando se complete la transición
  useEffect(() => {
    if (!showOverlay && showContent) {
      setTransitionInProgress(false);
      setShowStuckMessage(false);
      clearTimeouts();
    }
  }, [showOverlay, showContent]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        if (isLoading && transitionInProgress) {
          clearTimeouts();
          setShowOverlay(true);
          setShowContent(false);
          setShowStuckMessage(false);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isLoading, transitionInProgress]);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg={bgColor}
      overflow="hidden"
    >
      {/* Capa base - siempre visible */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg={bgColor}
        zIndex={1}
      />

      {/* Contenido principal */}
      <Box
        ref={contentRef}
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex={showContent ? 20 : 10}
        opacity={showContent ? 1 : 0}
        transition="opacity 0.3s ease-in-out"
        visibility={showContent ? "visible" : "hidden"}
        pointerEvents={showContent ? "auto" : "none"}
      >
        {children}
      </Box>

      {/* Overlay */}
      <Box
        ref={overlayRef}
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex={showOverlay ? 30 : 10}
        opacity={showOverlay ? 1 : 0}
        transition="opacity 0.3s ease-in-out"
        visibility={showOverlay ? "visible" : "hidden"}
        pointerEvents={showOverlay ? "auto" : "none"}
      >
        {overlay}
      </Box>

      {/* Mensaje de atasco - solo se muestra cuando realmente está atascado */}
      {showStuckMessage && transitionInProgress && (
        <VStack
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          spacing={4}
          zIndex={40}
          bg="rgba(0, 0, 0, 0.8)"
          p={6}
          borderRadius="md"
          maxW="90%"
          w="auto"
          textAlign="center"
        >
          <Text color="white" fontSize="lg">
            Parece que hay un problema con la carga.
          </Text>
          <Button
            colorScheme="blue"
            onClick={handleReload}
            isLoading={isReloading}
            loadingText="Recargando..."
          >
            Recargar página
          </Button>
        </VStack>
      )}
    </Box>
  );
};

export default ChakraTransitionContainer;
