/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { useState } from "react";
import { ImageProps, Image } from "@chakra-ui/react";

export const LazyImage: React.FC<Omit<ImageProps, "as">> = (props) => {
  // NOTE: 'as' is ignored because: https://github.com/chakra-ui/chakra-ui/issues/3714

  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Image
      opacity={isLoaded ? 1 : 0}
      transition="opacity 0.3s ease-out"
      loading="lazy"
      {...props}
      onLoad={() => setIsLoaded(true)}
    />
  );
};
