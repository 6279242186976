/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { IconControlsCamera, TypeIcon } from "../IconsControls";
import useCamera from "@/components/LiveSessionsZoom/Hooks/useCamera";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { t } from "i18next";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import { useCameraStorage } from "./hooks/useCameraStorage";

export const Camera = () => {
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);
  const { isVideoActive, toggleVideo, isStreamProcessing } = useCamera();
  const { getCameraPreferences, saveCameraPreferences } = useCameraStorage();
  const [hasInitialized, setHasInitialized] = useState(false);

  // Inicializar el estado de la cámara desde localStorage
  useEffect(() => {
    if (!hasInitialized && isMentorOrAdmin) {
      const preferences = getCameraPreferences();
      if (preferences.isVideoActive !== isVideoActive) {
        toggleVideo().catch((error) => {
          console.error("Error initializing camera state:", error);
        });
      }
      setHasInitialized(true);
    }
  }, [
    hasInitialized,
    isMentorOrAdmin,
    isVideoActive,
    toggleVideo,
    getCameraPreferences,
  ]);

  // Guardar cambios en localStorage
  useEffect(() => {
    if (hasInitialized && isMentorOrAdmin) {
      saveCameraPreferences(isVideoActive);
    }
  }, [hasInitialized, isMentorOrAdmin, isVideoActive, saveCameraPreferences]);

  const onCameraHandle = useCallback(async () => {
    if (!isMentorOrAdmin || isStreamProcessing) {
      return;
    }

    try {
      await toggleVideo();
      // El efecto anterior se encargará de guardar en localStorage
    } catch (error: any) {
      console.error("[Camera Component] Error:", error);
      SBErrorPubSub.publish({
        component: "Camera.tsx",
        message:
          error.message ||
          t(
            "No se pudo cambiar el estado de la cámara. Por favor, verifique la conexión y los permisos."
          ),
        showInProd: true,
      });
    }
  }, [toggleVideo, isMentorOrAdmin, isStreamProcessing]);

  const getTooltipMessage = () => {
    if (!isMentorOrAdmin) {
      return "Solo los mentores y administradores pueden controlar la cámara";
    }
    if (isStreamProcessing) {
      return isVideoActive ? "Desactivando cámara..." : "Activando cámara...";
    }
    return isVideoActive ? "Desactivar cámara" : "Activar cámara";
  };

  return (
    <Box>
      <Tooltip
        label={getTooltipMessage()}
        hasArrow
        placement="top"
        openDelay={200}
      >
        <Flex
          onClick={onCameraHandle}
          cursor={
            !isMentorOrAdmin || isStreamProcessing ? "not-allowed" : "pointer"
          }
          w={"115px"}
          maxW={"115px"}
          userSelect={"none"}
          justify={"center"}
          align={"center"}
          flexDir={"column"}
          opacity={isStreamProcessing ? 0.7 : 1}
          transition="all 0.2s"
          _hover={{
            opacity: !isMentorOrAdmin || isStreamProcessing ? 0.7 : 0.9,
          }}
          role="button"
          aria-label={getTooltipMessage()}
        >
          <IconControlsCamera
            type={isVideoActive ? TypeIcon.CAMERA_ON : TypeIcon.CAMERA_OFF}
          />
          <Text
            color={isVideoActive ? "#00E324" : "#FF4848"}
            fontSize={"10px"}
            lineHeight={"19.2px"}
          >
            Video {isVideoActive ? "activo" : "inactivo"}
          </Text>
        </Flex>
      </Tooltip>
    </Box>
  );
};
