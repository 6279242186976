/* eslint-disable react-refresh/only-export-components */
import validateComponentVisibility from "@/pages/Learn/validateOnboarding";
import { useUser } from "@/providers/useUser";
import { createContext } from "react";
import { useQueryLiveSessions } from "../../LiveClasses/utils";

interface IOnboardingContext {
  isOnboardingOpen: boolean;
  setOnboardingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isComponentVisible: boolean;
  validateIsScheduled: boolean;
  quantityScheduled: number;
}

export const onboardingContext = createContext<IOnboardingContext>({
  isOnboardingOpen: false,
  setOnboardingOpen: () => {},
  isComponentVisible: false,
  validateIsScheduled: false,
  quantityScheduled: 0,
});

export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isOnboardingOpen, setOnboardingOpen, user } = useUser();
  const createdAt = user?.createdAt?.toString() ?? "";
  const isComponentVisible = validateComponentVisibility(createdAt, true);
  const validateIsScheduled = validateComponentVisibility(createdAt, false);
  const dataOnboarding = useQueryLiveSessions(true);
  const quantityScheduled = dataOnboarding.schedulledLiveSessions.length;

  return (
    <onboardingContext.Provider
      value={{
        isOnboardingOpen,
        setOnboardingOpen,
        isComponentVisible,
        validateIsScheduled,
        quantityScheduled,
      }}
    >
      {children}
    </onboardingContext.Provider>
  );
};
