/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Tooltip, Divider } from "@chakra-ui/react";
import { BsWifi, BsSpeedometer2 } from "react-icons/bs";

// Definimos las interfaces necesarias
interface NetworkInformation extends EventTarget {
  readonly downlink: number;
  readonly effectiveType: "slow-2g" | "2g" | "3g" | "4g";
  readonly rtt: number;
  readonly saveData: boolean;
  readonly type: ConnectionType;
  onChange: (this: NetworkInformation, ev: Event) => void;
}

type ConnectionType =
  | "bluetooth"
  | "cellular"
  | "ethernet"
  | "none"
  | "wifi"
  | "wimax"
  | "other"
  | "unknown";

interface NavigatorNetworkInformation extends Navigator {
  readonly connection?: NetworkInformation;
  readonly mozConnection?: NetworkInformation;
  readonly webkitConnection?: NetworkInformation;
}

interface NetworkState {
  type: ConnectionType;
  effectiveType: "slow-2g" | "2g" | "3g" | "4g" | "unknown";
  downlinkSpeed: number;
  rtt: number;
  online: boolean;
}

interface LatencyQuality {
  text: string;
  color: string;
}

const NetworkInfo: React.FC = () => {
  const [networkInfo, setNetworkInfo] = useState<NetworkState>({
    type: "unknown",
    effectiveType: "unknown",
    downlinkSpeed: 0,
    rtt: 0,
    online: true,
  });

  const [latencyQuality, setLatencyQuality] = useState<LatencyQuality>({
    text: "Excelente",
    color: "green.400",
  });

  useEffect(() => {
    const updateNetworkInfo = async () => {
      try {
        const navigatorNetwork = navigator as NavigatorNetworkInformation;
        const connection =
          navigatorNetwork.connection ||
          navigatorNetwork.mozConnection ||
          navigatorNetwork.webkitConnection;

        // Hacer un ping simple para medir latencia
        const startTime = performance.now();
        await fetch("https://www.google.com/favicon.ico", { mode: "no-cors" });
        const endTime = performance.now();
        const pingTime = Math.round(endTime - startTime);

        setNetworkInfo({
          type: connection?.type || "unknown",
          effectiveType: connection?.effectiveType || "unknown",
          downlinkSpeed: connection?.downlink || 0,
          rtt: pingTime,
          online: navigator.onLine,
        });

        // Actualizar calidad basada en la latencia
        if (pingTime < 100) {
          setLatencyQuality({ text: "Excelente", color: "green.400" });
        } else if (pingTime < 200) {
          setLatencyQuality({ text: "Buena", color: "green.300" });
        } else if (pingTime < 300) {
          setLatencyQuality({ text: "Regular", color: "yellow.400" });
        } else {
          setLatencyQuality({ text: "Lenta", color: "red.400" });
        }
      } catch (error) {
        console.error("Error al obtener información de red:", error);
      }
    };

    updateNetworkInfo();
    const interval = setInterval(updateNetworkInfo, 5000);

    const handleOnline = () =>
      setNetworkInfo((prev) => ({ ...prev, online: true }));
    const handleOffline = () =>
      setNetworkInfo((prev) => ({ ...prev, online: false }));

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      clearInterval(interval);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const getConnectionType = (type: ConnectionType): string => {
    const types: Record<ConnectionType, string> = {
      wifi: "Wi-Fi",
      cellular: "Datos móviles",
      ethernet: "Cable",
      bluetooth: "Bluetooth",
      wimax: "WiMAX",
      other: "Otra",
      none: "Sin conexión",
      unknown: "Desconocida",
    };
    return types[type];
  };

  const getConnectionSpeed = (type: string): string => {
    const speeds: Record<string, string> = {
      "slow-2g": "Muy lenta",
      "2g": "Lenta",
      "3g": "Media",
      "4g": "Rápida",
      unknown: "Desconocida",
    };
    return speeds[type] || type;
  };

  return (
    <Box p={4} bg="transparent" borderRadius="8px" mb={4} border={"1px solid"}>
      <Flex alignItems="center" mb={2}>
        <BsWifi size={20} color={networkInfo.online ? "#38A169" : "#E53E3E"} />
        <Text ml={2} fontWeight="bold" color="secondary.300">
          Estado de tu Conexión
        </Text>
      </Flex>

      <Divider mb={4} />

      <Flex flexWrap="wrap" gap={4}>
        <Tooltip label="Tipo de conexión a internet">
          <Flex direction="column">
            <Text fontSize="sm" color="gray.500">
              Conexión
            </Text>
            <Text fontWeight="medium" color="secondary.300">
              {getConnectionType(networkInfo.type)}
            </Text>
          </Flex>
        </Tooltip>

        <Tooltip label="Velocidad de tu conexión">
          <Flex direction="column">
            <Text fontSize="sm" color="gray.500">
              Velocidad
            </Text>
            <Text fontWeight="medium" color="secondary.300">
              {getConnectionSpeed(networkInfo.effectiveType)}
            </Text>
          </Flex>
        </Tooltip>

        <Tooltip label="Tiempo de respuesta de tu conexión">
          <Flex direction="column">
            <Text fontSize="sm" color="gray.500">
              Respuesta
            </Text>
            <Text fontWeight="medium" color={latencyQuality.color}>
              {latencyQuality.text} ({networkInfo.rtt}ms)
            </Text>
          </Flex>
        </Tooltip>

        {networkInfo.downlinkSpeed > 0 && (
          <Tooltip label="Velocidad de descarga estimada">
            <Flex direction="column">
              <Text fontSize="sm" color="gray.500">
                Descarga
              </Text>
              <Flex align="center">
                <BsSpeedometer2 size={16} style={{ marginRight: "4px" }} />
                <Text fontWeight="medium" color="secondary.300">
                  {networkInfo.downlinkSpeed.toFixed(1)} Mbps
                </Text>
              </Flex>
            </Flex>
          </Tooltip>
        )}
      </Flex>
    </Box>
  );
};

export default NetworkInfo;
