import { useNavigate } from "react-router-dom";
import FilterItem from "./filterItem";
import { mainBrands } from "./data";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { AppRoute } from "@/AppRoute";
import { useContext } from "react";
import { learnContext } from "../providers/learnProvider";

const BrandFilter = () => {
  const { setSelectedBrand } = useContext(learnContext);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();

  return (
    <Flex
      gap={isMobile ? "16px" : "18px"}
      flexDir={{ base: "column", lg: "row" }}
      justifyContent={"space-between"}
      w={"100%"}
    >
      {mainBrands.map((brand) => (
        <FilterItem
          key={brand.name}
          brand={brand}
          handleClick={() => {
            setSelectedBrand(brand);
            navigate(`${AppRoute.Learn}${brand.route}`);
          }}
        />
      ))}
    </Flex>
  );
};

export default BrandFilter;
