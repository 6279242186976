import { useContext } from "react";
import { learnContext } from "../providers/learnProvider";

export const useUniqueCategories = () => {
  const { brandData } = useContext(learnContext);

  if (!brandData) return;

  const { bootcamps, liveSessions, programs } = brandData;

  const bootcampsSubCategories = [
    ...(bootcamps?.cta.map((bootcamp) => bootcamp?.parent?.subCategory?.name) ??
      []),
    ...(bootcamps?.myBootcamps.map(
      (bootcamp) => bootcamp?.parent?.subCategory?.name
    ) ?? []),
    ...(bootcamps?.completed.map(
      (bootcamp) => bootcamp?.parent?.subCategory?.name
    ) ?? []),
  ];

  const programsSubCategories =
    programs?.map((program) => program?.subCategory?.name) ?? [];

  const liveSessionsSubCategories = [
    ...(liveSessions?.next.map((session) => session?.subCategory?.name) ?? []),
    ...(liveSessions?.scheduled.map((session) => session?.subCategory?.name) ??
      []),
  ];

  const uniqueCategories = Array.from(
    new Set([
      ...bootcampsSubCategories,
      ...programsSubCategories,
      ...liveSessionsSubCategories,
    ])
  );

  return uniqueCategories;
};
