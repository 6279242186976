/* eslint-disable @typescript-eslint/no-shadow */
import {
  Badge,
  Box,
  Button,
  Flex,
  Progress,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Ico } from "@/assets/icons";
import { EditButton } from "@/components/Button/Edit";
import { Card } from "@/components/Card";
import { LazyImage } from "@/components/LazyImage";
import {
  CourseStatus,
  CourseVisibility,
  EntityName,
  UserRole,
} from "@/schemaTypes";
import { getCourseTagFromType } from "@/utils/courses";
import { TestIds } from "@/utils/testIds";
import type { CourseCardProps } from "./types";
import { MouseEventHandler } from "react";

const editRoles = [UserRole.Editor, UserRole.Admin];

const CourseCard = ({
  isList,
  badge,
  children,
  course,
  img,
  onClick,
  onDelete,
  onEdit,
  data_cy,
  isNewUi = false,
  showVisibility = false,
  ...restProps
}: CourseCardProps): JSX.Element => {
  const {
    isNew = false,
    name,
    progress,
    status,
    type = EntityName.Course,
    visibility = CourseVisibility.Public,
  } = course;
  const { t } = useTranslation();
  const tag = getCourseTagFromType(type);
  const truncateText = (text: string): string => {
    if (text.length <= 50) {
      return text;
    }
    return text.slice(0, 50) + "...";
  };

  const checkCategory = (category: string) => {
    if (
      category.toLowerCase() === "sin marca" ||
      category.toLowerCase() === "sin subcategoría" ||
      category.toLowerCase() === "sin nivel"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isGeneral =
    (!course.brand || course?.brand.name.toLowerCase() === "sin marca") &&
    (!course?.subCategory ||
      course?.subCategory.name.toLowerCase() === "sin subcategoría") &&
    (!course?.level || course?.level.name.toLowerCase() === "sin nivel");

  return (
    <Card
      pb={0}
      userSelect={"none"}
      onClick={isList ? (onClick as MouseEventHandler) : undefined}
      isNewUi={isNewUi}
      cursor={isList ? "pointer" : undefined}
      _hover={isList ? { bgColor: "neutral.50" } : undefined}
      shadow={isList ? "sm" : undefined}
      actionButtons={
        isList ? undefined : (
          <>
            <Button
              mt={0}
              fontSize={"14px"}
              display={isList ? "none" : "block"}
              data-cy={data_cy ? data_cy + "-" + name : undefined}
              onClick={onClick}
              rightIcon={isNewUi ? undefined : <Ico.ArrowRight />}
              variant={"link"}
            >
              {t(
                progress === 0
                  ? "Start"
                  : progress === 100
                  ? "SeeAgain"
                  : "Continue"
              )}
            </Button>
            <EditButton
              data-cy={TestIds.EditCourseButton}
              onClick={onEdit}
              roles={editRoles}
            />
            <EditButton
              _hover={{ bgColor: "red.400", color: "white" }}
              aria-label={"Delete"}
              colorScheme={"red"}
              icon={<Ico.Trash />}
              onClick={onDelete}
            />
          </>
        )
      }
      badge={isNew ? { children: t("New") } : undefined}
      img={img}
      showDivider={false}
      {...restProps}
    >
      <Flex
        align={"center"}
        gap={"8px"}
        mb={isNewUi || isList ? undefined : "16px"}
      >
        {badge === undefined ? null : (
          <LazyImage
            userSelect={"none"}
            draggable={false}
            aspectRatio={isNewUi ? 0 : 1}
            h={isNewUi ? undefined : "72px"}
            rounded={isNewUi ? "6px" : undefined}
            objectFit={"contain"}
            {...badge}
          />
        )}
        <Box>
          {!isNewUi && (
            <Text mb={"4px"} variant={"pretitle"}>
              {t(tag)}
            </Text>
          )}
          {status === CourseStatus.Draft ? (
            <Text mb={"4px"} variant={"feedback"}>
              {t("Draft")}
            </Text>
          ) : null}
          {!isNewUi && !isList && <Text variant={"bold"}>{name}</Text>}
          {isList && <Text variant={"bold"}>{truncateText(name)}</Text>}
        </Box>
      </Flex>
      {progress === "loading" ? (
        <Flex justify={"center"}>
          <Spinner color={"secondary.100"} size={"md"} thickness={"3px"} />
        </Flex>
      ) : (
        !isList && (
          <Flex align={"center"} justify={"center"} gap={"16px"}>
            <Progress
              my={isNewUi ? "10px" : 0}
              colorScheme={"secondary"}
              flexGrow={1}
              h={isNewUi ? "5px" : "8px"}
              rounded={"4px"}
              value={progress}
            />
            <Text variant={"feedback"}>{`${progress}%`}</Text>
          </Flex>
        )
      )}
      {showVisibility && visibility === CourseVisibility.Private && (
        <Box
          as={Ico.Lock}
          fontSize={"20px"}
          pos={"absolute"}
          right={"16px"}
          top={"16px"}
        />
      )}
      {children}
      {isNewUi &&
      (course.brand?.name !== "Sin marca" ||
        course.subCategory?.name !== "Sin subcategoría" ||
        course.level?.name !== "Sin nivel") ? (
        <Flex gap={2}>
          {[course.brand, course.subCategory, course.level].map((tag) =>
            tag && isNewUi && checkCategory(tag.name) ? (
              <Badge
                key={tag.id}
                px={"8px"}
                py={"2px"}
                bg={"neutral.50"}
                textTransform={"capitalize"}
                color={"neutral.500"}
                borderRadius={"6px"}
              >
                <Text variant={"feedback"}>{tag.name}</Text>
              </Badge>
            ) : null
          )}
        </Flex>
      ) : (
        isNewUi &&
        isGeneral && (
          <Badge
            key={"General"}
            px={"8px"}
            py={"2px"}
            bg={"neutral.50"}
            textTransform={"capitalize"}
            color={"neutral.500"}
            borderRadius={"6px"}
          >
            <Text variant={"feedback"}>General</Text>
          </Badge>
        )
      )}
    </Card>
  );
};

export { CourseCard };
