import useGA4Event, {
  GA4EventAction,
  GA4EventCategory,
  GA4EventLabel,
} from "@/hooks/useGA4Event";
import { LiveSession } from "@/schemaTypes";
import { useMutation } from "@apollo/client";
import {
  Flex,
  Heading,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { CancelSchedullingLiveSessionDocument } from "./graphql/CancelSchedullingLiveSession.generated";
import Warning from "/img/warning.svg";
import { LiveSessionCard } from "@/pages/academy/LiveClasses/types";
import { Button } from "../Button";

interface ModalCancelSchedullingLiveSessionProps {
  onClose: () => void;
  isOpen: boolean;
  sessionToSchedule?: LiveSession | LiveSessionCard;
  setupdateRequests: React.Dispatch<React.SetStateAction<boolean>>;
  sessionByWeek?: LiveSession | LiveSessionCard;
  refetchLiveSessions?: () => void;
}

const ModalCancelSchedullingLiveSession: React.FC<
  ModalCancelSchedullingLiveSessionProps
> = ({
  isOpen,
  onClose,
  setupdateRequests,
  sessionByWeek,
  refetchLiveSessions,
}) => {
  const [cancelSchedullingLiveSession, { loading }] = useMutation(
    CancelSchedullingLiveSessionDocument
  );

  const { sendEvent } = useGA4Event();

  const cancelToSession = async (session?: LiveSession) => {
    if (!session) return;

    const data = await cancelSchedullingLiveSession({
      variables: {
        liveSessionsId: session.id,
      },
    });

    if (data.data?.cancelSchedullingLiveSession) {
      setupdateRequests(true);
      onClose();
    } else {
      setupdateRequests(false);
    }

    if (refetchLiveSessions) {
      refetchLiveSessions();
    }

    sendEvent(
      {
        action: `${GA4EventAction.CancelLiveSessionAttendance}`,
        label: GA4EventLabel.CancelLiveSessionAttendance,
        category: GA4EventCategory.LiveSessions,
      },
      {
        sessionName: session.name,
      }
    );
  };

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} size={"md"}>
      <ModalOverlay />
      <ModalContent
        w={{ base: "90%", md: "500px" }}
        mx={{ base: "16px", lg: "0" }}
      >
        <ModalCloseButton />
        <ModalBody
          mt={10}
          pb={0}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Img src={Warning} alt="placeholder" />
          <Heading
            textAlign={"center"}
            mt={6}
            mb={2}
            as={"h5"}
            whiteSpace={{ base: "none", md: "nowrap" }}
          >
            <Trans>Are you sure about cancelling your attendance?</Trans>
          </Heading>
        </ModalBody>
        <ModalFooter
          as={Flex}
          flexDir={"column-reverse"}
          pt={0}
          justifyContent={"center"}
          gap={"16px"}
          my={"40px"}
        >
          <Button
            w={"full"}
            variant={"secondary"}
            colorScheme="teal"
            borderWidth={"2px"}
            onClick={() => cancelToSession(sessionByWeek as LiveSession)}
            isLoading={loading}
            isDisabled={loading}
          >
            <Trans>Yes, cancel</Trans>
          </Button>

          <Button
            w={"full"}
            bg={"primary.500"}
            variant={"primary"}
            gap={12}
            onClick={onClose}
            isDisabled={loading}
          >
            <Trans>No</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCancelSchedullingLiveSession;
