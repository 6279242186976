/* eslint-disable no-console */
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useCameraStorage = () => {
  const STORAGE_KEY = "zoom_camera_preferences";
  const location = useLocation();

  const extractSessionId = useCallback(() => {
    // La ruta puede ser /clase-en-vivo/broadcast/:id o /configuracion-dispositivos/:id
    const pathParts = location.pathname.split("/");
    return pathParts[pathParts.length - 1].split("?")[0]; // Obtener el ID sin los query params
  }, [location]);

  const getCameraPreferences = useCallback(() => {
    try {
      const currentSessionId = extractSessionId();
      const stored = localStorage.getItem(STORAGE_KEY);

      if (stored) {
        const preferences = JSON.parse(stored);

        // Si la sesión es diferente o no hay sessionId, retornar valores por defecto
        if (
          !preferences.sessionId ||
          preferences.sessionId !== currentSessionId
        ) {
          return {
            isVideoActive: false,
            lastUpdated: new Date().toISOString(),
            sessionId: currentSessionId,
          };
        }

        return preferences;
      }
    } catch (error) {
      console.error("Error reading camera preferences:", error);
    }

    // Valores por defecto para una nueva sesión
    return {
      isVideoActive: false,
      lastUpdated: new Date().toISOString(),
      sessionId: extractSessionId(),
    };
  }, [extractSessionId]);

  const saveCameraPreferences = useCallback(
    (isVideoActive: boolean) => {
      try {
        const currentSessionId = extractSessionId();
        const preferences = {
          isVideoActive,
          lastUpdated: new Date().toISOString(),
          sessionId: currentSessionId,
        };
        localStorage.setItem(STORAGE_KEY, JSON.stringify(preferences));
      } catch (error) {
        console.error("Error saving camera preferences:", error);
      }
    },
    [extractSessionId]
  );

  return { getCameraPreferences, saveCameraPreferences };
};
