/* eslint-disable no-console */
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import ZoomVideo, {
  ConnectionState,
  MediaSDKEncDecPayload,
  ReconnectReason,
  RecordingClient,
  VideoClient,
} from "@zoom/videosdk";
import ZoomContext from "./zoom-context";
import ZoomMediaContext from "./media-context";
import CommandContext from "./cmd-context";
import ChatContext from "./chat-context";
import { mediaReducer, mediaShape } from "./reducer/MediaReducer";
import { ChatClient, CommandChannelClient, MediaStream } from "../types/zoom";
import { config } from "@/config";
import { clientReducer, clientStore } from "./reducer/ClientReducer";
import { useUser } from "@/providers/useUser";
import { VideoStateProvider } from "./video-state-context";
import { t } from "i18next";

export interface MeetingArgs {
  topic: string;
  token: string;
  username: string;
  password?: string;
}

interface AppProps {
  children: ReactNode;
}

const ZoomProvider = (props: AppProps) => {
  const { children } = props;
  const { user } = useUser();
  const [loading, setIsLoading] = useState(true);
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [zmChatClient, setChatClient] = useState<ChatClient | null>(null);
  const [zmcommandClient, setCommandClient] =
    useState<CommandChannelClient | null>(null);
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [isOtherUserVideoActive, setIsOtherUserVideoActive] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [sdkKey] = useState(config.SDK_KEY_ZOOM);
  const screenSharedElement = useRef(null);
  const screenSharedElementVideo = useRef(null);
  const videoCamerasElement = useRef(null);
  const [status, setStatus] = useState<string>("closed");
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [recordingClient, setrecordingClient] = useState<
    typeof RecordingClient | undefined
  >(undefined);
  const [clientState, dispatchClient] = useReducer(clientReducer, clientStore);
  const [zmClient, setzmClient] = useState<typeof VideoClient>();
  const [isClientInitialized, setIsClientInitialized] = useState(false);

  const mediaContext = useMemo(
    () => ({
      ...mediaState,
      mediaStream,
      isVideoActive,
      isOtherUserVideoActive,
      setIsVideoActive,
      setIsOtherUserVideoActive,
    }),
    [mediaState, mediaStream]
  );

  const handleZoomError = useCallback((error: unknown, context: string) => {
    let errorMessage = "An unexpected error occurred";

    if (error instanceof Error) {
      switch (context) {
        case "initialization":
          errorMessage = `Zoom initialization failed: ${error.message}. Compruebe su conexión a Internet y la compatibilidad de su navegador.`;
          break;
        case "connection":
          errorMessage = `Connection error: ${error.message}. Compruebe su conexión de red.`;
          break;
        case "media":
          errorMessage = `Media error: ${error.message}. Por favor, verifica los permisos de tu cámara y micrófono.`;
          break;
        case "meeting":
          errorMessage = `Meeting error: ${error.message}. Por favor, intenta reincorporarte a la sesión.`;
          break;
        case "cleanup":
          errorMessage = `Cleanup error: ${error.message}. Puede que sea necesario actualizar la sesión manualmente.`;
          break;
        default:
          errorMessage = `Zoom error: ${error.message}`;
      }
    }

    console.error(`[Zoom ${context}]:`, errorMessage);
    return errorMessage;
  }, []);

  useEffect(() => {
    const initializeZoomClient = () => {
      try {
        if (!sdkKey) {
          throw new Error(" SDK Key no está configurado en la aplicación");
        }

        if (zmClient) {
          ZoomVideo.destroyClient();
        }

        const client = ZoomVideo.createClient();

        if (!client) {
          throw new Error(
            "No se ha podido crear el cliente Zoom - es posible que su navegador no sea compatible"
          );
        }

        client.init("es-ES", "Global", {
          patchJsMedia: true,
          stayAwake: true,
          enforceMultipleVideos: true,
          leaveOnPageUnload: true,
        });

        setzmClient(client);
        setIsClientInitialized(true);
        console.log("[Zoom] Cliente inicializado correctamente");
      } catch (error) {
        const errorMessage = handleZoomError(error, "initialization");
        console.error(errorMessage);
        setIsClientInitialized(false);
      }
    };

    initializeZoomClient();

    return () => {
      try {
        if (zmClient) {
          ZoomVideo.destroyClient();
        }
      } catch (error) {
        handleZoomError(error, "cleanup");
      }
    };
  }, [sdkKey, handleZoomError]);

  const initZoomClient = useCallback(
    async ({ token, topic, username }: MeetingArgs) => {
      if (!zmClient || !isClientInitialized) {
        throw new Error(
          "El cliente Zoom no se ha inicializado. Espere e inténtelo de nuevo."
        );
      }

      setIsLoading(true);
      setLoadingText("Iniciando conexión...");

      try {
        if (!token || !topic || !username) {
          throw new Error("Faltan las credenciales necesarias para la reunión");
        }

        // Creamos una promesa que se resolverá cuando la conexión sea exitosa
        const joinPromise = new Promise<void>((resolve, reject) => {
          const connectionHandler = (payload: { state: ConnectionState }) => {
            if (payload.state === ConnectionState.Connected) {
              if (zmClient.getSessionInfo()?.isInMeeting) {
                zmClient.off("connection-change", connectionHandler);
                resolve();
              }
            } else if (payload.state === ConnectionState.Fail) {
              zmClient.off("connection-change", connectionHandler);
              reject(new Error("Falló la conexión"));
            }
          };

          // Escuchamos el evento de cambio de conexión
          zmClient.on("connection-change", connectionHandler);

          // Iniciamos el join
          zmClient.join(topic, token, username).catch((error) => {
            zmClient.off("connection-change", connectionHandler);
            reject(error);
          });
        });

        // Esperamos a que el join se complete
        await joinPromise;
        console.log("[Zoom] Join completed successfully");

        // Solo después de que el join está completo, inicializamos los demás servicios
        const stream = zmClient.getMediaStream();
        if (!stream) {
          throw new Error("No se pudo obtener el flujo multimedia");
        }
        setMediaStream(stream);
        console.log("[Zoom] Media stream initialized");

        if (user) {
          try {
            await zmClient.changeName(
              `${user?.name} ${user?.lastName}`,
              Number(username)
            );
            console.log("[Zoom] Username updated");
          } catch (error) {
            console.warn("[Zoom] Failed to set username:", error);
          }
        }

        const chatClient = zmClient.getChatClient();
        const commandClient = zmClient.getCommandClient();
        const recordingClientInstance = zmClient.getRecordingClient();

        if (!chatClient || !commandClient) {
          console.warn("[Zoom] Chat or command features might be limited");
        }

        setCommandClient(commandClient);
        setChatClient(chatClient);
        setrecordingClient(recordingClientInstance);
        console.log("[Zoom] Communication channels initialized");

        setIsLoading(false);
        console.log("[Zoom] Full initialization completed");
      } catch (error) {
        setIsLoading(false);
        const errorMessage = handleZoomError(error, "meeting");
        throw new Error(errorMessage);
      }
    },
    [zmClient, isClientInitialized, user, handleZoomError]
  );

  const onConnectionChange = useCallback(
    (payload: { state?: ConnectionState; reason: ReconnectReason }) => {
      const { state = null, reason } = payload;
      console.log("[Zoom] Connection state change:", state, "Reason:", reason);

      try {
        if (state === ConnectionState.Reconnecting) {
          // Limpiar servicios si es necesario
          if (zmClient?.getMediaStream()) {
            try {
              zmClient.getMediaStream().stopAudio();
            } catch (error) {
              console.warn("[Zoom] Error cleaning up audio:", error);
            }
          }

          setIsLoading(true);
          setIsFailover(true);
          setStatus("connecting");
          setLoadingText(t("Reconnecting to your session..."));
        } else if (state === ConnectionState.Connected) {
          if (zmClient?.getSessionInfo()?.isInMeeting) {
            setStatus("connected");

            if (isFailover) {
              setTimeout(() => {
                setIsLoading(false);
                setIsFailover(false);
              }, 1000);
            }
          }
        } else if (state === ConnectionState.Closed) {
          setStatus("closed");
          setIsLoading(false);

          if (zmClient?.getMediaStream()) {
            try {
              zmClient.getMediaStream().stopAudio();
            } catch (error) {
              console.warn("[Zoom] Error cleaning up audio:", error);
            }
          }
        }
      } catch (error) {
        console.error("[Zoom] Error handling connection change:", error);
        handleZoomError(error, "connection");
      }
    },
    [isFailover, handleZoomError, zmClient]
  );

  const onMediaSDKChange = useCallback(
    (payload: MediaSDKEncDecPayload) => {
      try {
        const { action, type, result } = payload;
        dispatch({ type: `${type}-${action}`, payload: result === "success" });
      } catch (error) {
        handleZoomError(error, "media");
      }
    },
    [handleZoomError]
  );

  useEffect(() => {
    if (!zmClient || !isClientInitialized) return;

    try {
      zmClient.on("connection-change", onConnectionChange);
      zmClient.on("media-sdk-change", onMediaSDKChange);

      return () => {
        zmClient.off("connection-change", onConnectionChange);
        zmClient.off("media-sdk-change", onMediaSDKChange);
      };
    } catch (error) {
      handleZoomError(error, "event-listeners");
    }
  }, [zmClient, isClientInitialized, onConnectionChange, onMediaSDKChange]);

  return (
    <ZoomContext.Provider
      value={{
        clientState,
        initZoomClient: (args: MeetingArgs) => initZoomClient(args),
        dispatch: dispatchClient,
        zmClient,
        refVideosElements: {
          screenSharedElementAudience: screenSharedElement.current,
          screenSharedElementVideo: screenSharedElementVideo.current,
          videoCamerasElement: videoCamerasElement.current,
        },
        videoCamerasElement: "video-player-container",
        dataSessionAuth: clientState?.dataSessionAuth,
        clienteRecording: recordingClient,
      }}
    >
      <ZoomMediaContext.Provider
        value={{
          mediaContext,
          loadingZoom: loading,
          loadingText,
          statusZoom: status,
          dispatch,
        }}
      >
        <ChatContext.Provider value={zmChatClient}>
          <CommandContext.Provider value={zmcommandClient}>
            <VideoStateProvider>{children}</VideoStateProvider>
          </CommandContext.Provider>
        </ChatContext.Provider>
      </ZoomMediaContext.Provider>
    </ZoomContext.Provider>
  );
};

export default ZoomProvider;
