import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateLiveSessionSchedulingMutationVariables = Types.Exact<{
  createLiveSessionsSchedulingInput: Types.CreateLiveSessionsSchedulingInput;
}>;


export type CreateLiveSessionSchedulingMutation = { __typename?: 'Mutation', createLiveSessionScheduling: { __typename?: 'LiveSession', id: string, name: string, channelName: string, userId: string, moduleId?: string | null, imageUrl?: string | null, programId?: string | null, startDate: Date, endDate: Date, status?: Types.LiveSessionStatus | null } };


export const CreateLiveSessionSchedulingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateLiveSessionScheduling"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createLiveSessionsSchedulingInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateLiveSessionsSchedulingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createLiveSessionScheduling"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createLiveSessionsSchedulingInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createLiveSessionsSchedulingInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"channelName"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"moduleId"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"programId"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<CreateLiveSessionSchedulingMutation, CreateLiveSessionSchedulingMutationVariables>;