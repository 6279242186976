/* eslint-disable no-console */
import { useCallback } from "react";

interface ShareState {
  lastState: boolean;
  timestamp: string;
  systemPreferences: Record<string, unknown>;
  currentSharer?: {
    userId: string | number;
    name: string;
    startTime: string;
  };
}

export const useScreenShareState = () => {
  const STORAGE_KEY = "zoom_screen_share_state";

  const getStoredState = useCallback((): ShareState => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      if (stored) {
        return JSON.parse(stored);
      }
    } catch (error) {
      console.error("Error reading screen share state:", error);
    }
    return {
      lastState: false,
      timestamp: new Date().toISOString(),
      systemPreferences: {},
    };
  }, []);

  const saveState = useCallback(
    (
      isSharing: boolean,
      preferences = {},
      sharerInfo?: { userId: string | number; name: string }
    ) => {
      try {
        const state: ShareState = {
          lastState: isSharing,
          timestamp: new Date().toISOString(),
          systemPreferences: preferences,
        };

        if (isSharing && sharerInfo) {
          state.currentSharer = {
            userId: sharerInfo.userId,
            name: sharerInfo.name,
            startTime: new Date().toISOString(),
          };
        }

        localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
      } catch (error) {
        console.error("Error saving screen share state:", error);
      }
    },
    []
  );

  const clearSharerInfo = useCallback(() => {
    try {
      const currentState = getStoredState();
      delete currentState.currentSharer;
      localStorage.setItem(STORAGE_KEY, JSON.stringify(currentState));
    } catch (error) {
      console.error("Error clearing sharer info:", error);
    }
  }, [getStoredState]);

  return { getStoredState, saveState, clearSharerInfo };
};
