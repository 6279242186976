export enum ConnectionStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  RECONNECTING = "RECONNECTING",
  ZOOM_ERROR = "ZOOM_ERROR",
}

export interface ReconnectionState {
  status: ConnectionStatus;
  lastError: string | null;
  attemptCount: number;
  isReconnectionInProgress: boolean;
}
