/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { IconButton, IconButtonProps } from "@chakra-ui/react";
import React from "react";

import { Ico } from "@/assets/icons";

interface EditButtonProps {
  onClick: (e: React.MouseEvent) => void;
}

export const EditButton: React.FC<
  EditButtonProps & Omit<IconButtonProps, "aria-label" | "as">
  // NOTE: 'as' is ignored because: https://github.com/chakra-ui/chakra-ui/issues/3714
> = ({ onClick, ...props }) => {
  return (
    <IconButton
      alignSelf="flex-end"
      justifySelf="flex-end"
      icon={<Ico.PenToSquare />}
      onClick={onClick}
      size="sm"
      color="primary.400"
      colorScheme="primary"
      aria-label="edit"
      rounded="full"
      variant="ghost"
      _hover={{ bgColor: "primary.400", color: "white" }}
      {...props}
    />
  );
};
