import { PageContainer } from "@/components/PageContainer";
import OnlinePrograms from "../../OnlinePrograms";
import LiveSessions from "../LiveClasses/LiveSessions";
import GuideTraining from "../../GuideTraining";
import SubCategoryFilter from "./subCategoryFilter/subCategoryFilter";
import BrandFilter from "./brandFilter/brandFilter";
import { Divider, Flex, useBreakpointValue } from "@chakra-ui/react";
import StartGuide from "./startGuide";
import { ContinueProgress } from "@/components/ContinueToProgress/continueProgress";
import { LearnParams } from "./providers/types";
import { useParams } from "react-router-dom";
import { LearnProvider } from "./providers/learnProvider";
import OnboardingBanner from "@/components/OnboardingBanner";
import { useContext } from "react";
import { onboardingContext } from "./providers/onboardingProvider";
import ModalOnboarding from "@/components/ModalOnboarding";

const Learn = () => {
  const { subroute: selectedSubpage } = useParams<LearnParams>();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const {
    isOnboardingOpen,
    setOnboardingOpen,
    isComponentVisible,
    validateIsScheduled,
    quantityScheduled,
  } = useContext(onboardingContext);

  return (
    <LearnProvider>
      <PageContainer
        noGap
        px={isMobile ? 0 : "32px"}
        mt={isMobile ? "24px" : "40px"}
        pt={isMobile ? "24px" : "40px"}
        pb={isMobile ? "80px" : "136px"}
      >
        {isComponentVisible ||
        (validateIsScheduled && quantityScheduled > 0) ? (
          <Flex
            justifyContent={"center"}
            mt={4}
            mb={4}
            px={isMobile ? "16px" : "0"}
          >
            <OnboardingBanner />
          </Flex>
        ) : null}

        <ContinueProgress />

        <Flex
          px={isMobile ? "16px" : 0}
          gap={isMobile ? "16px" : "18px"}
          flexDir={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          w={"100%"}
        >
          {isMobile && !selectedSubpage && <StartGuide />}

          {selectedSubpage ? <SubCategoryFilter /> : <BrandFilter />}
        </Flex>

        <Flex py={4} justifyContent={"flex-end"} alignItems={"center"}>
          {!isMobile && !selectedSubpage && <StartGuide />}
        </Flex>

        <Divider borderBottomWidth={"0.5px"} />

        <LiveSessions />

        <Divider borderBottomWidth={"0.5px"} />

        <OnlinePrograms />

        <Divider borderBottomWidth={"0.5px"} />

        <GuideTraining />

        <ModalOnboarding
          isOpen={isOnboardingOpen}
          onClose={() => {
            setOnboardingOpen(false);
          }}
        />
      </PageContainer>
    </LearnProvider>
  );
};

export default Learn;
