import { Box, Flex, ResponsiveValue, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface EmpityStateComponentProps {
  dataFontSize: ResponsiveValue<string | number>;
  textUp: string;
  textDown: string;
  IconComponent: React.ElementType;
}

const EmpityStateComponent: React.FC<EmpityStateComponentProps> = ({
  dataFontSize,
  textUp,
  textDown,
  IconComponent,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      gap={{ base: 4, lg: 6 }}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box fontSize={dataFontSize}>
        <IconComponent />
      </Box>
      <Flex>
        <Text
          fontSize={{ base: "12px", lg: "14px" }}
          fontWeight={600}
          lineHeight={{ base: "19.2px", lg: "22.4px" }}
          textAlign={"center"}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          {t(textUp)}
          <Text
            as={"span"}
            fontSize={{ base: "12px", lg: "14px" }}
            fontWeight={400}
            lineHeight={{ base: "19.2px", lg: "22.4px" }}
            textAlign={"center"}
          >
            {t(textDown)}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};

export default EmpityStateComponent;
