import { AppRoute } from "@/AppRoute";
import { EntityName } from "@/schemaTypes";

const coursePathToPageTitle: Partial<Record<AppRoute, string>> = {
  [AppRoute.AdvancedCourse]: "Advanced courses",
  [AppRoute.Courses]: "Courses",
  [AppRoute.Programs]: "Course",
  [AppRoute.Specializations]: "Course",
};

const mainPathToPageTitle: Partial<Record<AppRoute, string>> = {
  [AppRoute.AdvancedCourse]: "Advanced course",
  [AppRoute.Courses]: "Courses",
  [AppRoute.Programs]: "diplomat",
  [AppRoute.Specializations]: "Specialization",
};

const coursePathToType: Partial<Record<AppRoute, EntityName>> = {
  [AppRoute.AdvancedCourse]: EntityName.AdvancedCourse,
  [AppRoute.Courses]: EntityName.RegularCourse,
  [AppRoute.Programs]: EntityName.RegularCourse,
  [AppRoute.Specializations]: EntityName.RegularCourse,
};

const courseTypeToPath: Partial<Record<EntityName, AppRoute>> = {
  [EntityName.AdvancedCourse]: AppRoute.AdvancedCourse,
  [EntityName.Course]: AppRoute.Courses,
};

const courseTypeToTag: Partial<Record<EntityName, string>> = {
  [EntityName.AdvancedCourse]: "Advanced course",
  [EntityName.ComplementaryCourse]: "Complementary course",
  [EntityName.Course]: "Course",
  [EntityName.Diplomat]: "Diploma course",
  [EntityName.Specialization]: "Specialization",
};

const getCoursePathFromType = (
  path: EntityName = EntityName.Course
): AppRoute => {
  return courseTypeToPath[path] ?? AppRoute.Courses;
};

const getCourseTagFromType = (type: EntityName = EntityName.Course): string => {
  return courseTypeToTag[type] ?? "Course";
};

const getCourseTitleFromPath = (path: AppRoute): string => {
  return coursePathToPageTitle[path] ?? "Courses";
};

const getMainPathToPageTitle = (path: AppRoute): string => {
  return mainPathToPageTitle[path as AppRoute] ?? "Courses";
};

const getCourseTypeFromPath = (path: AppRoute): EntityName => {
  return coursePathToType[path] ?? EntityName.Course;
};

export {
  getCoursePathFromType,
  getCourseTagFromType,
  getCourseTitleFromPath,
  getCourseTypeFromPath,
  getMainPathToPageTitle,
};
