import { AppRoute } from "@/AppRoute";
import { LiveSessionCard } from "@/pages/academy/LiveClasses/types";
import {
  parseLiveSessionsDate,
  useQueryLiveSessions,
} from "@/pages/academy/LiveClasses/utils";
import { UpdateUnboxingUserDocument } from "@/pages/Unboxing/graphql/updateUnboxingStatus.generated";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import { useMutation } from "@apollo/client";
import { Flex, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const OnboardingBanner = () => {
  const navigate = useNavigate();
  const { user, hasRoles, geolocation } = useUser();
  const timezone = geolocation.location;
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const isMentor = hasRoles([UserRole.Mentor]);
  const [enterOption, setEnterOption] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const { schedulledLiveSessions } = useQueryLiveSessions(true);

  const [updateUnboxingUser] = useMutation(UpdateUnboxingUserDocument, {
    errorPolicy: "all",
    update: (cache) => {
      if (user) {
        cache.evict({ id: `User:${user.id}` });
      }
    },
  });

  const handleNavigate = () => {
    navigate(AppRoute.Onboarding);
  };

  const isEnterAllowed = (startDate: Date, isNewMentor: boolean) => {
    const now = moment();
    const momentStartDate = isNewMentor
      ? moment(startDate).clone().subtract(5, "minutes")
      : moment(startDate);
    return now.isSameOrAfter(momentStartDate);
  };

  const enterToSession = (session: LiveSessionCard) => {
    if (!session.startDate || !isEnterAllowed(session.startDate, isMentor))
      return;
    updateUnboxingUser({ variables: { userId: user?.id ?? "" } });
    const liveSessionUrl = isMentor
      ? `${AppRoute.SettingDevices}/${session?.id}?redirect=${window.location.href}`
      : `${AppRoute.LiveSessionStreaming}/${session?.id}`;

    navigate(liveSessionUrl);
  };

  useEffect(() => {
    if (schedulledLiveSessions.length > 0) {
      setEnterOption(
        new Date(schedulledLiveSessions[0]?.startDate ?? "") <= new Date()
      );
      setTimeOver(
        new Date(schedulledLiveSessions[0]?.endDate ?? "") <= new Date()
      );
    }
  }, [schedulledLiveSessions]);

  return (
    <Flex
      bg={"#FCF7DB"}
      borderRadius={"8px"}
      w={"100%"}
      justifyContent={"center"}
    >
      <Flex
        padding={"16px"}
        gap={2}
        alignItems={"center"}
        flexDir={isMobile ? "column" : "row"}
      >
        {schedulledLiveSessions.length > 0 && !timeOver ? (
          <>
            <Text fontSize={"14px"} lineHeight={"22.4px"}>
              🎉{" "}
              <Text as="b" fontWeight={600}>
                Tu Sesión de Bienvenida
              </Text>{" "}
              está confirmada para el{" "}
              <Text as="b" fontWeight={600}>
                {
                  parseLiveSessionsDate(
                    schedulledLiveSessions[0]?.startDate ?? new Date(),
                    timezone,
                    false
                  ).format
                }
              </Text>{" "}
              <Text as="b" fontWeight={600}>
                a las
              </Text>{" "}
              <Text as="b" fontWeight={600}>
                {
                  parseLiveSessionsDate(
                    schedulledLiveSessions[0]?.startDate ?? new Date(),
                    timezone
                  ).time
                }
              </Text>
            </Text>
            <Flex gap={3} alignItems={"center"}>
              <Link
                fontSize={"14px"}
                color={
                  enterOption
                    ? "primary.300"
                    : isMentor &&
                      isEnterAllowed(
                        schedulledLiveSessions[0]?.startDate as Date,
                        true
                      )
                    ? "primary.300"
                    : "gray.400"
                }
                pb={"2px"}
                onClick={() =>
                  enterToSession(schedulledLiveSessions[0] as LiveSessionCard)
                }
                cursor={
                  enterOption
                    ? "pointer"
                    : isMentor &&
                      isEnterAllowed(
                        schedulledLiveSessions[0]?.startDate as Date,
                        true
                      )
                    ? "pointer"
                    : "not-allowed"
                }
              >
                Entrar a la sesión
              </Link>
              <Text color={"primary.300"} fontSize={"14px"}>
                {" | "}
              </Text>
              <Link
                color={"primary.300"}
                pb={"2px"}
                onClick={handleNavigate}
                fontSize={"14px"}
              >
                Reagendar
              </Link>
            </Flex>
          </>
        ) : (
          <>
            <Text fontSize={"14px"}>
              🚀 Regístrate a una Sesión de Onboarding y aprovecha al máximo
              nuestra plataforma.
            </Text>
            <Link
              color={"primary.300"}
              onClick={handleNavigate}
              fontSize={"14px"}
            >
              ¡Regístrate ahora!
            </Link>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default OnboardingBanner;
