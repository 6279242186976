import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Camera } from "./Camera/Camera";
import { Microphone } from "./Microphone/Microphone";
import { ScreenShared } from "./ScreenShared/ScreenShared";
import { LeaveSession } from "./Leave/Leave";
import ZoomClientContext from "../../context/zoom-context";
import { useContext } from "react";
import { Start } from "./Start";
import { Fullscreen } from "./FullScreen/Fullscreen";
import { UserRole } from "@/schemaTypes";
import { useUser } from "@/providers/useUser";

interface propsInterface {}

export const CustomControls: React.FC<propsInterface> = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { zmClient } = useContext(ZoomClientContext);
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);

  return (
    <Flex
      h={"100%"}
      backgroundColor={"#27272E"}
      borderLeft={"1px solid #32323A"}
      alignItems={"center"}
      borderTopRadius={"8PX"}
      border={"1px solid #32323A"}
      p={2}
      w={"100%"}
    >
      {zmClient?.getSessionInfo().isInMeeting && isMentorOrAdmin && (
        <Flex
          w={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={{ base: 0, lg: 8 }}
          color={"white"}
        >
          <Box>
            <Microphone />
          </Box>
          <Box>
            <Camera />
          </Box>
          <Box>
            <ScreenShared />
          </Box>
        </Flex>
      )}

      <Flex alignItems={"center"} w={isMentorOrAdmin ? "none" : "100%"}>
        {!isMobile &&
          zmClient?.getSessionInfo().isInMeeting &&
          isMentorOrAdmin && (
            <>
              <Box>
                <LeaveSession />
              </Box>

              {!isMobile && (
                <Box>
                  <Fullscreen />
                </Box>
              )}
            </>
          )}

        {!isMentorOrAdmin && (
          <Box w={"100%"} display={"flex"} justifyContent={"flex-end"}>
            <Fullscreen />
          </Box>
        )}

        {!zmClient?.getSessionInfo().isInMeeting && (
          <Box hidden={!isMentorOrAdmin}>
            <Start />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
