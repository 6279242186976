/* eslint-disable no-console */
import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { IconControlsMicrophone, TypeIcon } from "../IconsControls";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import { Trans } from "react-i18next";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";

export const Microphone = () => {
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);
  const [isStartedAudio, setIsStartedAudio] = useState(false);
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);

  const getSelectedDevices = () => {
    try {
      const selectedDevices = localStorage.getItem("selectedDevices");
      return selectedDevices ? JSON.parse(selectedDevices) : null;
    } catch {
      return null;
    }
  };

  // Función para verificar si la conexión está completamente establecida
  const isFullyConnected = useCallback(() => {
    if (!zmClient) return false;

    try {
      const isInMeeting = zmClient.getSessionInfo()?.isInMeeting;
      const isConnected = zoomMediaContext?.statusZoom === "connected";
      const mediaStream = zoomMediaContext?.mediaContext?.mediaStream;

      return (
        isInMeeting &&
        isConnected &&
        mediaStream !== null &&
        mediaStream !== undefined
      );
    } catch (error) {
      console.warn("Error checking connection status:", error);
      return false;
    }
  }, [
    zmClient,
    zoomMediaContext?.statusZoom,
    zoomMediaContext?.mediaContext?.mediaStream,
  ]);

  const initializeAudio = useCallback(async () => {
    // Verificar que estamos realmente conectados antes de inicializar
    if (!isFullyConnected()) {
      console.log(
        "[Audio] Waiting for full connection before initializing audio"
      );
      return;
    }

    try {
      const mediaStream = zoomMediaContext?.mediaContext?.mediaStream;
      if (!mediaStream) {
        console.log("[Audio] Media stream not available");
        return;
      }

      if (!isAudioInitialized) {
        console.log("[Audio] Starting audio initialization");
        const selectedDevices = getSelectedDevices();

        // Intentar inicializar el audio
        await mediaStream.startAudio({
          speakerId: selectedDevices?.speaker,
          mute: true, // Empezar siempre muteado
        });

        await mediaStream.muteAudio();
        setIsAudioInitialized(true);
        setIsStartedAudio(false);
        console.log("[Audio] Audio initialized successfully");
      }
    } catch (error) {
      console.error("[Audio] Error initializing audio:", error);
      setIsAudioInitialized(false);
      setIsStartedAudio(false);
    }
  }, [
    zmClient,
    zoomMediaContext?.mediaContext?.mediaStream,
    isAudioInitialized,
    isFullyConnected,
  ]);

  const onMicrophoneHandle = useCallback(async () => {
    if (!isMentorOrAdmin || !zoomMediaContext?.mediaContext?.mediaStream) {
      return;
    }

    try {
      if (!isFullyConnected()) {
        console.log("[Audio] Session not fully connected");
        return;
      }

      const mediaStream = zoomMediaContext.mediaContext.mediaStream;

      if (!isAudioInitialized) {
        await initializeAudio();
        // Retornamos aquí para evitar intentar unmute inmediatamente después de la inicialización
        return;
      }

      if (!isStartedAudio) {
        await mediaStream.unmuteAudio();
        setIsStartedAudio(true);
      } else {
        await mediaStream.muteAudio();
        setIsStartedAudio(false);
      }
    } catch (error) {
      console.error("[Audio] Error toggling microphone:", error);
      setIsAudioInitialized(false);
      setIsStartedAudio(false);
    }
  }, [
    isStartedAudio,
    isAudioInitialized,
    zmClient,
    zoomMediaContext?.mediaContext?.mediaStream,
    isMentorOrAdmin,
    initializeAudio,
    isFullyConnected,
  ]);

  // Efecto para inicializar el audio cuando la conexión esté lista
  useEffect(() => {
    let initializationAttempt: NodeJS.Timeout;

    if (!isAudioInitialized && isFullyConnected()) {
      // Pequeño delay para asegurar que todo está listo
      initializationAttempt = setTimeout(() => {
        initializeAudio();
      }, 1000);
    }

    return () => {
      if (initializationAttempt) {
        clearTimeout(initializationAttempt);
      }

      const mediaStream = zoomMediaContext?.mediaContext?.mediaStream;
      if (mediaStream && isFullyConnected()) {
        try {
          mediaStream.muteAudio();
          setIsStartedAudio(false);
        } catch (error) {
          console.warn("[Audio] Error in cleanup:", error);
        }
      }
    };
  }, [
    isFullyConnected,
    zoomMediaContext?.mediaContext?.mediaStream,
    isAudioInitialized,
    initializeAudio,
  ]);

  // Manejar actualizaciones de usuario
  useEffect(() => {
    const handleUserUpdate = () => {
      try {
        if (!isFullyConnected()) return;

        const currentUser = zmClient?.getCurrentUserInfo();
        if (!currentUser) return;

        const users = zmClient?.getAllUser();
        if (!users) return;

        const user = users.find((u) => u.userId === currentUser.userId);
        if (user) {
          setIsStartedAudio(!user.muted);
        }
      } catch (error) {
        console.warn("[Audio] Error updating user state:", error);
      }
    };

    if (zmClient) {
      zmClient.on("user-updated", handleUserUpdate);
      return () => {
        zmClient.off("user-updated", handleUserUpdate);
      };
    }
  }, [zmClient, isFullyConnected]);

  return (
    <Box>
      <Flex
        onClick={onMicrophoneHandle}
        cursor={isMentorOrAdmin ? "pointer" : "not-allowed"}
        w={"115px"}
        maxW={"115px"}
        userSelect={"none"}
        justify={"center"}
        align={"center"}
        flexDir={"column"}
      >
        <IconControlsMicrophone
          type={isStartedAudio ? TypeIcon.MICROFONE_ON : TypeIcon.MICROFONE_OFF}
        />
        <Text
          color={isStartedAudio ? "#00E324" : "#FF4848"}
          fontSize={"10px"}
          lineHeight={"19.2px"}
        >
          <Trans>{isStartedAudio ? "Active" : "Disabled"} Audio</Trans>
        </Text>
      </Flex>
    </Box>
  );
};
