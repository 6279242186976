import React, { useState } from "react";
import { Box, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { brandCardProps } from "./types";

const FilterItem: React.FC<brandCardProps> = ({ brand, handleClick }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [isActive, setIsActive] = useState(false);

  const handleMouseDown = () => setIsActive(true);
  const handleMouseUp = () => setIsActive(false);

  return (
    <Box
      transition={"all 0.3s"}
      userSelect={"none"}
      border={"1px solid"}
      borderColor={"neutral.100"}
      w={isMobile ? "100%" : "33%"}
      minW={"30%"}
      pl={5}
      borderRadius={"8px"}
      cursor={"pointer"}
      py={5}
      bg={isActive ? "primary.400" : "white"}
      color={isActive ? "white" : "auto"}
      _hover={{
        borderColor: "primary.400",
      }}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onMouseLeave={() => setIsActive(false)}
    >
      <Flex gap={3} alignItems={"center"}>
        {isActive ? brand.cleanIcon : brand.icon}
        <Flex flexDir={"column"}>
          <Heading as="h6">{brand.name}</Heading>
          <Text variant={"caption"}>{brand.description}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default FilterItem;
