/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useRef } from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import { Participant, VideoQuality } from "@zoom/videosdk";
import { useVideoState } from "@/components/LiveSessionsZoom/context/videoState/useVideoState";
import {
  DISPATCH_TYPE,
  EVENTS,
} from "@/components/LiveSessionsZoom/types/LiveBar.type";

interface CameraGridProps {
  userId?: number;
}

export const CameraGrid: React.FC<CameraGridProps> = ({ userId }) => {
  const zoomMediaContext = useContext(ZoomMediaContext);
  const { state, dispatch } = useVideoState();
  const { zmClient } = useContext(ZoomClientContext);
  const [cameraListNumber, setCameraListNumber] = useState<Array<number>>([]);
  const videoRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  const validateParticipant = (user: Participant) => {
    if (!user || typeof user.userId !== "number" || user.userId <= 0) {
      console.warn("Invalid user data:", user);
      return false;
    }
    if (typeof user.bVideoOn !== "boolean") {
      console.warn("Invalid video status for user:", user.userId);
      return false;
    }
    return true;
  };

  const getColumnsCamera = (cameraList: number, isScreenSharing: boolean) => {
    try {
      console.log("Calculating grid columns:", { cameraList, isScreenSharing });

      if (isScreenSharing) {
        console.log("Screen sharing active, setting single column");
        dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 1 });
        return;
      }

      if (cameraList <= 1) {
        console.log("Single camera, setting single column");
        dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 1 });
        return;
      }

      if (cameraList > 1 && cameraList <= 4) {
        console.log("2-4 cameras, setting two columns");
        dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 2 });
        return;
      }

      console.log("Default case, setting single column");
      dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 1 });
    } catch (error) {
      console.error("Error calculating grid columns:", error);
      // Fallback to single column
      dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 1 });
    }
  };

  useEffect(() => {
    if (!zmClient || !userId) {
      console.log("Zoom client or userId not available");
      return;
    }

    const updateCameraList = () => {
      try {
        console.log("Updating camera list for userId:", userId);

        const participants = zmClient.getAllUser();
        console.log("Total participants:", participants.length);

        // Filter valid participants with video on
        const validParticipants = participants.filter(validateParticipant);
        console.log("Valid participants:", validParticipants.length);

        const cameraList = validParticipants
          .filter((user) => user.bVideoOn && user.userId !== userId)
          .map((user) => user.userId);

        // Check if current user has video on
        if (
          validParticipants.find(
            (user) => user.userId === userId && user.bVideoOn
          )
        ) {
          console.log("Adding current user to camera list");
          cameraList.unshift(userId);
        }

        console.log("Final camera list:", cameraList);
        setCameraListNumber(cameraList);

        const isScreenSharing = validParticipants.some((user) => user.sharerOn);
        console.log("Screen sharing status:", isScreenSharing);

        getColumnsCamera(cameraList.length, isScreenSharing);
      } catch (error) {
        console.error("Error updating camera list:", error);
      }
    };

    updateCameraList();

    zmClient.on(EVENTS.USER_UPDATED, updateCameraList);
    zmClient.on(EVENTS.USER_ADDED, updateCameraList);
    zmClient.on(EVENTS.USER_REMOVED, updateCameraList);

    return () => {
      console.log("Cleaning up camera grid event listeners");
      zmClient.off(EVENTS.USER_UPDATED, updateCameraList);
      zmClient.off(EVENTS.USER_ADDED, updateCameraList);
      zmClient.off(EVENTS.USER_REMOVED, updateCameraList);
    };
  }, [zmClient, userId]);

  useEffect(() => {
    const attachVideos = async () => {
      try {
        const mediaStream = zoomMediaContext?.mediaContext.mediaStream;
        if (
          !mediaStream?.isSupportMultipleVideos ||
          !mediaStream ||
          cameraListNumber.length === 0
        ) {
          console.log("Skipping video attachment - prerequisites not met");
          return;
        }

        console.log("Attaching videos for users:", cameraListNumber);

        for (const userIdCamera of cameraListNumber) {
          const videoContainer = videoRefs.current[userIdCamera];
          if (!videoContainer) {
            console.warn("Video container not found for user:", userIdCamera);
            continue;
          }

          if (videoContainer.childElementCount > 0) {
            console.log("Video already attached for user:", userIdCamera);
            continue;
          }

          try {
            console.log("Attaching video for user:", userIdCamera);
            const videoElement = await mediaStream.attachVideo(
              userIdCamera,
              VideoQuality.Video_720P
            );

            videoContainer.innerHTML = "";
            videoContainer.appendChild(videoElement as Node);
            console.log("Video successfully attached for user:", userIdCamera);
          } catch (attachError) {
            console.error(
              `Error attaching video for user ${userIdCamera}:`,
              attachError
            );
          }
        }
      } catch (error) {
        console.error("Error in video attachment process:", error);
      }
    };

    attachVideos();
  }, [zoomMediaContext, cameraListNumber]);

  return (
    <Grid
      display={state.isScreenShareActive ? "flex" : "grid"}
      gap={2}
      flexDirection={"column"}
      width="100%"
      height={state.isScreenShareActive ? "none" : "100%"}
      templateColumns={
        cameraListNumber.length === 3 && !state.isScreenShareActive
          ? "repeat(2, 1fr)"
          : `repeat(${state.isGridCamera}, 1fr)`
      }
      templateRows={
        cameraListNumber.length === 3 && !state.isScreenShareActive
          ? "repeat(2, 1fr)"
          : "1fr"
      }
      overflow="hidden"
      marginBottom={"20px"}
      marginTop={"20px"}
      padding={
        !state.isScreenShareActive && cameraListNumber.length === 1 ? 0 : 2
      }
    >
      {cameraListNumber.map((userIdCamera, index) => (
        <GridItem
          key={userIdCamera}
          colSpan={cameraListNumber.length === 3 ? (index === 2 ? 2 : 1) : 1}
          rowSpan={1}
          overflow="hidden"
          display="flex"
          justifyContent={"center"}
        >
          <Box
            key={userIdCamera}
            width={
              cameraListNumber.length === 3 && !state.isScreenShareActive
                ? index === 2
                  ? "50%"
                  : "100%"
                : "100%"
            }
            height="100%"
            backgroundColor="secondary.300"
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            borderRadius={"8px"}
          >
            <Box
              id="video-container"
              width="100%"
              height="auto"
              borderRadius={"8px"}
              overflow={"hidden"}
              sx={{
                "& > video-player-container": {
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  "& > video-player": {
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                    aspectRatio: "16/9",
                    objectFit: "contain",
                  },
                },
              }}
            >
              <Box
                borderRadius={"8px"}
                as="video-player-container"
                id={`camera-${userIdCamera}`}
                ref={(el) => (videoRefs.current[userIdCamera] = el)}
              ></Box>
            </Box>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
};
