/* eslint-disable no-console */
import React, { Component, ErrorInfo } from "react";
import { Box, Button, Text, VStack } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error capturado:", {
      error,
      errorInfo,
      componentStack: errorInfo.componentStack,
    });
  }

  private handleReload = () => {
    window.location.reload();
  };

  private handleRetry = () => {
    this.setState({ hasError: false, error: null });
  };

  public render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="secondary.300"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <VStack spacing={6} maxW="600px" p={8}>
            <Text color="white" fontSize="xl" textAlign="center">
              Lo sentimos, ha ocurrido un error inesperado.
            </Text>
            <Text color="white" fontSize="md" textAlign="center" opacity={0.8}>
              {this.state.error?.message || "Error desconocido"}
            </Text>
            <Button
              colorScheme="blue"
              onClick={this.handleReload}
              size="lg"
              mt={4}
            >
              Recargar página
            </Button>
            <Button
              variant="ghost"
              color="white"
              onClick={this.handleRetry}
              size="md"
            >
              Intentar recuperar
            </Button>
          </VStack>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
