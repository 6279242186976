import {
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Ico } from "@/assets/icons";
import arraySteps from "@/utils/datas_json/onboardingSteps.json";

const ModalOnboarding = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = arraySteps.length;
  const imageSrc = useBreakpointValue({
    base: arraySteps[currentStep].imageMobile,
    lg: arraySteps[currentStep].imageDesktop,
  });

  const handleNext = () => {
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      localStorage.setItem("popup_onbording_completed", "true");
      onClose();
    }
  };

  const handleStepClick = (index: number) => {
    setCurrentStep(index);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "sm", lg: "xl" }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay background="secondary.300" opacity={"0.8 !important"} />
      <ModalContent margin={{ base: "24px", lg: "64px" }}>
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          gap={6}
          padding={6}
          paddingBottom={8}
        >
          <Flex width={{ base: "auto", lg: "528px" }} height={"400px"}>
            <Image src={imageSrc} w={"100%"} />
          </Flex>
          <Flex flexDirection={"column"} alignItems={"center"} gap={10}>
            <Flex
              flexDirection={"column"}
              alignItems={"center"}
              gap={2}
              justifyContent={"center"}
              height={{ base: "190px", lg: "136px" }}
              w="100%"
            >
              <Text
                fontSize={{ base: "16px", lg: "24px" }}
                fontWeight={600}
                lineHeight={{ base: "25.6px", lg: "36px" }}
                color={"secondary.300"}
                textAlign={"center"}
              >
                {t(arraySteps[currentStep].title)}
              </Text>
              <Text
                fontSize={{ base: "12px", lg: "14px" }}
                fontWeight={400}
                lineHeight={"22.4px"}
                color={"secondary.100"}
                textAlign={"center"}
              >
                {t(arraySteps[currentStep].description)}
              </Text>
            </Flex>

            <Flex flexDirection="column" alignItems="center" gap={6} w="100%">
              <Flex gap={3} alignItems={"center"}>
                {arraySteps.map((_, index) => (
                  <Box
                    key={index}
                    width={index === currentStep ? "8px" : "6px"}
                    height={index === currentStep ? "8px" : "6px"}
                    borderRadius="50%"
                    backgroundColor={
                      index === currentStep ? "secondary.300" : "neutral.300"
                    }
                    onClick={() => handleStepClick(index)}
                    cursor="pointer"
                  />
                ))}
              </Flex>
              <Button
                w={{ base: "100%", lg: "280px" }}
                variant="primary"
                onClick={handleNext}
                {...(currentStep === totalSteps - 1 && {
                  rightIcon: (
                    <Ico.ArrowRight fontSize={"20px"} color="neutral.white" />
                  ),
                })}
              >
                {currentStep === totalSteps - 1 ? t("¡Start!") : t("Next")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
export default ModalOnboarding;
