import { DateTime } from "luxon";
import moment from "moment";
import "moment/locale/es";
import { DevicesId } from "./Settings/SettingsDevices";
moment.locale("es");
moment.utc();

interface PropChevronStatus {
  status: string;
}

export const ConvertDateToLocalString = (
  date: Date,
  timezone: string,
  format?: string
) => {
  if (!date && !timezone) return "";
  const time = DateTime.fromJSDate(date)
    .setLocale("es")
    .setZone(timezone)
    .toFormat(format ?? "cccc, dd 'de' LLLL");
  return time.charAt(0).toUpperCase() + time.slice(1);
};

export const SaveDeviceSelected = (selectedDevicesData: DevicesId) => {
  localStorage.setItem("selectedDevices", JSON.stringify(selectedDevicesData));
};

export const GetHourstoDate = (
  date: Date,
  timezone: string,
  format?: string
) => {
  if (!date && timezone) return "";

  const time = DateTime.fromJSDate(date)
    .setLocale("en")
    .setZone(timezone)
    .toFormat(format ?? "h':'mm a");

  const hours = DateTime.fromJSDate(new Date(date))
    .setLocale("en")
    .setZone(timezone);
  const hoursString = hours.toLocaleString(DateTime.TIME_SIMPLE);

  return format ? time : hoursString;
};

export const WeekDaySelector = (date: Date, timezone = "America/Bogota") => {
  const currentDate = DateTime.now().setZone(timezone);
  const selectedDate = DateTime.fromJSDate(date).setZone(timezone);

  const daysOfWeek = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];
  const dayOfWeek = daysOfWeek[selectedDate.weekday - 1];

  if (currentDate.hasSame(selectedDate, "day")) {
    return "Hoy";
  } else {
    return dayOfWeek;
  }
};

export const CustomChevron = ({ status }: PropChevronStatus) => {
  switch (status) {
    case "ACTIVE BACK":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.35349 12.8534C7.88217 12.3821 7.88217 11.6179 8.35349 11.1466L14.1466 5.35349C14.6179 4.88217 15.3821 4.88217 15.8534 5.35349C16.3247 5.82481 16.3247 6.58898 15.8534 7.0603L10.9137 12L15.8534 16.9397C16.3247 17.411 16.3247 18.1752 15.8534 18.6465C15.3821 19.1178 14.6179 19.1178 14.1466 18.6465L8.35349 12.8534Z"
            fill="#628ADF"
          />
        </svg>
      );

    case "DISABLED BACK":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.35349 12.8534C7.88217 12.3821 7.88217 11.6179 8.35349 11.1466L14.1466 5.35349C14.6179 4.88217 15.3821 4.88217 15.8534 5.35349C16.3247 5.82481 16.3247 6.58898 15.8534 7.0603L10.9137 12L15.8534 16.9397C16.3247 17.411 16.3247 18.1752 15.8534 18.6465C15.3821 19.1178 14.6179 19.1178 14.1466 18.6465L8.35349 12.8534Z"
            fill="#DADADC"
          />
        </svg>
      );

    case "ACTIVE NEXT":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.207 12C16.207 12.3201 16.0799 12.6271 15.8535 12.8534L10.0604 18.6465C9.58911 19.1178 8.82495 19.1178 8.35363 18.6465C7.8823 18.1752 7.8823 17.411 8.35363 16.9397L13.2933 12L8.35363 7.0603C7.8823 6.58898 7.8823 5.82481 8.35363 5.35349C8.82495 4.88217 9.58911 4.88217 10.0604 5.35349L15.8535 11.1466C16.0799 11.3729 16.207 11.6799 16.207 12Z"
            fill="#628ADF"
          />
        </svg>
      );

    case "DISABLED NEXT":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.207 12C16.207 12.3201 16.0799 12.6271 15.8535 12.8534L10.0604 18.6465C9.58911 19.1178 8.82495 19.1178 8.35363 18.6465C7.8823 18.1752 7.8823 17.411 8.35363 16.9397L13.2933 12L8.35363 7.0603C7.8823 6.58898 7.8823 5.82481 8.35363 5.35349C8.82495 4.88217 9.58911 4.88217 10.0604 5.35349L15.8535 11.1466C16.0799 11.3729 16.207 11.6799 16.207 12Z"
            fill="#DADADC"
          />
        </svg>
      );
  }
};
