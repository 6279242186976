import {
  Avatar,
  Badge,
  Box,
  Center,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";
import { LiveSessionCard as LiveSessionCardType } from "./types";
import { Trans } from "react-i18next";
import { parseLiveSessionsDate, useLiveSessionStatus } from "./utils";
import { Button } from "@/components/Button";
import { LiveSessionType, UserRole } from "@/schemaTypes";
import { Ico } from "@/assets/icons";
import { useUser } from "@/providers/useUser";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";

interface LiveSessionCardProps {
  session: LiveSessionCardType;
  handleScheduleClick: (session: LiveSessionCardType) => void;
  handleCancelScheduleClick: (session: LiveSessionCardType) => void;
  enterToSession: (session: LiveSessionCardType) => void;
}

const LiveSessionCard: React.FC<LiveSessionCardProps> = ({
  session,
  handleScheduleClick,
  handleCancelScheduleClick,
  enterToSession,
}) => {
  const { geolocation, hasRoles } = useUser();
  const timezone = geolocation.location;
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);

  const { showCancelOption, showEnterOption, showRegisterOption, isGeneral } =
    useLiveSessionStatus(session);

  const [canEnterEarly, setCanEnterEarly] = useState(false);

  const checkCanEnterEarly = useCallback(() => {
    if (!session.isScheduled) return false;

    const now = DateTime.now().setZone(timezone);
    const sessionStart = DateTime.fromISO(session.startDate.toString(), {
      zone: timezone,
    });
    const earlyAccessTime = sessionStart.minus({ minutes: 15 });

    return isMentorOrAdmin && now >= earlyAccessTime && now < sessionStart;
  }, [session.startDate, session.isScheduled, timezone, isMentorOrAdmin]);

  useEffect(() => {
    const checkAndSetEarlyAccess = () => {
      const canEnter = checkCanEnterEarly();
      setCanEnterEarly(canEnter);

      if (!canEnter && session.isScheduled) {
        const sessionStart = DateTime.fromISO(session.startDate.toString(), {
          zone: timezone,
        });
        const earlyAccessTime = sessionStart.minus({ minutes: 15 });
        const now = DateTime.now().setZone(timezone);
        const msUntilEarlyAccess = earlyAccessTime.diff(now).as("milliseconds");

        if (msUntilEarlyAccess > 0) {
          setTimeout(checkAndSetEarlyAccess, msUntilEarlyAccess);
        }
      }
    };

    checkAndSetEarlyAccess();
  }, [
    session.startDate,
    session.isScheduled,
    timezone,
    isMentorOrAdmin,
    checkCanEnterEarly,
  ]);

  const checkCategory = (category: string) =>
    !["sin marca", "sin subcategoría", "sin nivel"].includes(
      category.toLowerCase()
    );

  const liveSessionParsedDate = parseLiveSessionsDate(
    session?.startDate ?? new Date(),
    timezone
  );

  const handleEnterSession = useCallback(() => {
    if (canEnterEarly || showEnterOption) {
      enterToSession(session);
    }
  }, [canEnterEarly, showEnterOption, enterToSession, session]);

  const mentorName =
    session?.mainMentors[0]?.name + " " + session?.mainMentors[0]?.lastName;

  return (
    <Flex
      key={session?.name}
      alignSelf={"stretch"}
      flexGrow={1}
      minW={"300px"}
      maxW={"300px"}
      maxH={"261px"}
      flexDir={"column"}
      userSelect={"none"}
      justifyContent={"space-between"}
    >
      <Flex
        flexGrow={1}
        flexDir={"column"}
        rounded={"6px"}
        maxHeight={"189px"}
        bg={session.type?.color ?? "shades.200"}
        p={"12px"}
        gap={"8px"}
      >
        <Avatar
          w={"40px"}
          h={"40px"}
          src={session?.mainMentors[0]?.avatarUrl}
        />
        <Box>
          <Text
            fontWeight={700}
            variant={"caption"}
            textTransform={"uppercase"}
            fontFamily={"Poppins"}
            fontSize={"12px"}
          >
            <Trans>{session.type?.name}</Trans>
          </Text>
          <Text fontWeight={600} variant={"caption"} h={"45px"}>
            {session?.name}
          </Text>
        </Box>
        <Box>
          <Text fontWeight={600} variant={"caption"}>
            {`${liveSessionParsedDate.format} | ${liveSessionParsedDate.time}`}
          </Text>
          <Text fontWeight={400} variant={"caption"}>
            Con: {mentorName}
          </Text>
        </Box>
      </Flex>

      <Flex align={"flex-start"} gap={"8px"} mt={"8px"}>
        {isGeneral && (
          <Badge
            key={"General"}
            px={"8px"}
            py={"2px"}
            bg={"neutral.50"}
            textTransform={"capitalize"}
            w={"fit-content"}
            color={"neutral.500"}
            borderRadius={"6px"}
          >
            <Text variant={"feedback"}>General</Text>
          </Badge>
        )}

        {!isGeneral &&
          [
            session?.brand?.name,
            session?.subCategory?.name,
            session?.level?.name,
          ].map(
            (category) =>
              category &&
              checkCategory(category) && (
                <Badge
                  key={category}
                  px={"8px"}
                  py={"2px"}
                  bg={"neutral.50"}
                  textTransform={"capitalize"}
                  color={"neutral.500"}
                  borderRadius={"6px"}
                >
                  <Text variant={"feedback"}>{category}</Text>
                </Badge>
              )
          )}
      </Flex>

      <Flex flexDirection={"row"} mt={"16px"}>
        {session?.isCancelled && (
          <Button
            fontSize={"14px"}
            lineHeight={1}
            p={0}
            height={"22px"}
            variant="link"
            isDisabled
            cursor={"not-allowed"}
          >
            <Trans>Canceled by the teacher</Trans>
          </Button>
        )}
        {(showEnterOption || canEnterEarly) && (
          <Button
            fontSize={"14px"}
            lineHeight={1}
            p={0}
            height={"22px"}
            variant={"link"}
            onClick={handleEnterSession}
            rightIcon={<Ico.ArrowRight width={"18px"} height={"18px"} />}
          >
            <Trans>Enter Live Class</Trans>
          </Button>
        )}
        {showRegisterOption && !canEnterEarly && (
          <Button
            height={"22px"}
            fontSize={"14px"}
            lineHeight={1}
            p={0}
            variant={"link"}
            onClick={() => handleScheduleClick(session)}
          >
            {session.liveSessionType === LiveSessionType.Masterclass
              ? "Regístrate"
              : "Guarda tu puesto"}
          </Button>
        )}
        {showCancelOption && !canEnterEarly && (
          <Flex alignItems="center">
            <Badge
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="success.300"
              color="white"
              borderRadius="6px"
              fontSize="10px"
              fontWeight={600}
              w="73px"
              h="24px"
              lineHeight={"19px"}
            >
              Agendada
            </Badge>
            <Center height="24px" mx={"8px"}>
              <Divider orientation="vertical" />
            </Center>
            <Button
              fontSize="14px"
              fontStyle={"italic"}
              lineHeight={1}
              p={0}
              onClick={() => handleCancelScheduleClick(session)}
              fontWeight={400}
              variant="link"
              color="secondary.300"
              _hover={{
                color: "secondary.100",
              }}
              _active={{
                color: "secondary.400",
              }}
            >
              Cancelar
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default LiveSessionCard;
