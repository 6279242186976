import {
  Bootcamp,
  BootcampBannerEntry,
  LiveSession,
  Program,
} from "@/schemaTypes";
import { useQuery } from "@apollo/client";
import { GetLiveSessionsForCarrouselDocument } from "./graphql/getLiveSessionsForCarrousel.generated";
import { noCacheHeaders } from "@/utils/headers";
import { GetProgramsDocument } from "@/pages/OnlinePrograms/graphql/getPrograms.generated";

export const filterBootcampsByCategories = (
  categoryType: keyof Pick<
    Bootcamp,
    "subCategory" | "level" | "category" | "brand"
  >,
  categoryName: string,
  bootcamps: {
    completed: BootcampBannerEntry[];
    cta: BootcampBannerEntry[];
    myBootcamps: BootcampBannerEntry[];
  }
) => {
  if (!bootcamps) return { completed: [], cta: [], myBootcamps: [] };

  const filteredCompleted = bootcamps.completed.filter((bootcamp) => {
    const categoryField = bootcamp.parent?.[categoryType];
    const canFilterByCategory =
      categoryField &&
      typeof categoryField === "object" &&
      "name" in categoryField;

    if (canFilterByCategory) {
      return categoryField.name?.toLowerCase() === categoryName.toLowerCase();
    }

    return false;
  });

  const filteredCta = bootcamps.cta.filter((bootcamp) => {
    const categoryField = bootcamp.parent?.[categoryType];
    const canFilterByCategory =
      categoryField &&
      typeof categoryField === "object" &&
      "name" in categoryField;

    if (canFilterByCategory) {
      return categoryField.name?.toLowerCase() === categoryName.toLowerCase();
    }

    return false;
  });

  const filteredMyBootcamps = bootcamps.myBootcamps.filter((bootcamp) => {
    const categoryField = bootcamp.parent?.[categoryType];
    const canFilterByCategory =
      categoryField &&
      typeof categoryField === "object" &&
      "name" in categoryField;

    if (canFilterByCategory) {
      return categoryField.name?.toLowerCase() === categoryName.toLowerCase();
    }

    return false;
  });

  const filteredBootcamps = {
    completed: filteredCompleted,
    cta: filteredCta,
    myBootcamps: filteredMyBootcamps,
  };

  return filteredBootcamps;
};

export const filterLiveSessionsByCategories = (
  categoryType: keyof Pick<LiveSession, "subCategory" | "level" | "brand">,
  {
    next,
    scheduled,
  }: {
    next: LiveSession[];
    scheduled: LiveSession[];
  },
  categoryName: string
) => {
  if (!next || !scheduled) return { next: [], scheduled: [] };

  const filteredNext = next?.filter((session) => {
    const categoryField = session?.[categoryType];
    const canFilterByCategory =
      categoryField &&
      typeof categoryField === "object" &&
      "name" in categoryField;

    if (canFilterByCategory) {
      return categoryField.name?.toLowerCase() === categoryName.toLowerCase();
    }

    return false;
  });

  const filteredScheduled = scheduled?.filter((session) => {
    const categoryField = session?.[categoryType];
    const canFilterByCategory =
      categoryField &&
      typeof categoryField === "object" &&
      "name" in categoryField;

    if (canFilterByCategory) {
      return categoryField.name?.toLowerCase() === categoryName.toLowerCase();
    }

    return false;
  });

  return {
    next: filteredNext,
    scheduled: filteredScheduled,
  };
};

export const filterProgramsByCategories = (
  categoryType: keyof Pick<Program, "subCategory" | "level" | "brand">,
  programs: Program[],
  categoryName: string
) => {
  if (!programs) return [];

  const filteredPrograms = programs.filter((program) => {
    const categoryField = program?.[categoryType];
    const canFilterByCategory =
      categoryField &&
      typeof categoryField === "object" &&
      "name" in categoryField;

    if (canFilterByCategory) {
      return categoryField.name?.toLowerCase() === categoryName?.toLowerCase();
    }

    return false;
  });

  return filteredPrograms;
};

export const useQuerySessions = () => {
  const { data, loading, refetch } = useQuery(
    GetLiveSessionsForCarrouselDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  return {
    sessions: data?.getLiveSessionsForCarrousel.liveSessions,
    scheduledSessions: data?.getLiveSessionsForCarrousel.scheduledSessions,
    loading,
    refetch,
  };
};

export const useQueryProgramsData = () => {
  const { data: rawPrograms, loading } = useQuery(GetProgramsDocument, {
    variables: {
      courseType: "ADVANCED_COURSE",
    },
  });

  const parsedCourses = rawPrograms?.courses.map((course) => {
    return {
      ...course,
      programType: course.courseType,
    };
  });

  const parsedPrograms = rawPrograms?.searchAllPrograms.filter(
    (program) => program.status === "PUBLISHED"
  );

  return {
    allPrograms: [...(parsedPrograms || []), ...(parsedCourses || [])].sort(
      (a, b) => (a?.order ?? 0) - (b?.order ?? 0)
    ),
    getProgramsLoading: loading,
  };
};
