import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { brandCard } from "./types";

export const mainBrands: brandCard[] = [
  {
    name: "Amazon",
    description: "Vende en USA",
    icon: <Ico.Amazon fontSize={"30px"} />,
    cleanIcon: <Ico.CleanAmazon fontSize={"30px"} />,
    route: AppRoute.Amazon,
  },
  {
    name: "Dropshipping",
    description: "Vende en Latam",
    icon: <Ico.Dropshipping fontSize={"30px"} />,
    cleanIcon: <Ico.CleanDropshipping fontSize={"30px"} />,
    route: AppRoute.Dropshipping,
  },
  {
    name: "Mercado Libre",
    description: "Vende en Latam",
    icon: <Ico.Meli fontSize={"35px"} />,
    cleanIcon: <Ico.CleanMeli fontSize={"35px"} />,
    route: AppRoute.Meli,
  },
];
