import { AppRoute } from "@/AppRoute";
import { useContext, useEffect, useState } from "react";
import { Course, Program } from "@/schemaTypes";
import { Flex, Heading } from "@chakra-ui/react";
import { FilterOptions } from "@/components/AcademicFilter/filterTypes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CourseCard, CourseCardProps } from "@/components/Courses/CourseCard";
import { ProgramCard } from "@/components/Programs/ProgramCard";
import { ProgramCardFragment } from "@/components/Programs/graphql/programCardFragment.generated";
import AcademyCarousel from "@/components/AcademyCarousel";
import { learnContext } from "../academy/Learn/providers/learnProvider";
import { BigSpinner } from "@/components/BigSpinner";

type CourseListItem = CourseCardProps["course"] &
  Pick<Course, "id"> & {
    cohort?: string;
  };

// THIS INTERFACE IS DEPRECATED. PLEASE, DO NOT USE IT.
// refer to learnContext instead
interface OnlineProgramsProps {
  selectSubCategory?: string | null;
  programs?: (Program | Course)[];
}

const OnlinePrograms: React.FC<OnlineProgramsProps> = () => {
  const context = useContext(learnContext);

  if (!context) {
    throw new Error("You probably forgot to put <LearnProvider>.");
  }

  const { programs, programsLoading } = context;
  const [filteredprograms, setFilteredprograms] = useState(programs);

  const navigate = useNavigate();

  const [queryParams] = useSearchParams();

  const handleItemCourseClick = ({ id, cohort }: CourseListItem) => {
    navigate(
      `${AppRoute.AdvancedCourse}/${id}${AppRoute.Cohorts}/${cohort}${
        AppRoute.Dashboard
      }?${queryParams.toString()}`,
      { replace: true }
    );
  };

  const handleItemProgramClick = ({ id }: ProgramCardFragment) => {
    navigate(`${AppRoute.Programs}/${id}`);
  };

  const handleEditClick = ({ id }: ProgramCardFragment) => {
    navigate(`${AppRoute.Programs}/${id}/edit`);
  };

  const handleFilterbuttonClick = (filterLabel: string) => {
    const selectedFilter = FilterOptions.find(
      (option) => option.label === filterLabel
    );
    if (!selectedFilter) return;

    if (selectedFilter?.value === FilterOptions[0].value)
      return setFilteredprograms(programs);

    const filtered = programs
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => item.programType === selectedFilter?.value
      )
      .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));

    return setFilteredprograms(filtered);
  };

  useEffect(() => {
    setFilteredprograms(programs);
  }, [programs]);

  return (
    <Flex flexDir={"column"} mt={"24px"} mb={"22px"}>
      <>
        <Heading as={"h5"} ml={{ base: "16px", lg: 0 }} mb={"16px"}>
          Programas Online
        </Heading>
        {programsLoading && <BigSpinner />}
        {!programsLoading && (
          <AcademyCarousel
            filterOptions={FilterOptions.map((option) => option.label)}
            handleFilterChange={handleFilterbuttonClick}
          >
            {filteredprograms?.map((element, index) =>
              element.__typename === "Course" ? (
                <CourseCard
                  isNewUi={true}
                  key={index}
                  course={element as CourseListItem}
                  onClick={handleItemCourseClick}
                />
              ) : (
                <ProgramCard
                  isNewUi={true}
                  program={element as Program}
                  key={index}
                  _badges={(element as Course).badges ?? []}
                  onClick={handleItemProgramClick}
                  onEditClick={handleEditClick}
                />
              )
            )}
          </AcademyCarousel>
        )}
      </>
    </Flex>
  );
};

export default OnlinePrograms;
