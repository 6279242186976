import { useLazyQuery } from "@apollo/client";
import { useUser } from "@/providers/useUser";
import { BootcampBannerEntry, EntityName, UserRole } from "@/schemaTypes";
import { GetBootcampsForBannersDocument } from "../academy/Bootcamps/graphql/getBootcampsForBanners.generated";
import { useEffect } from "react";

export const useQueryBootcampsData = () => {
  const { hasRoles } = useUser();
  const isAdmin = hasRoles([UserRole.Admin]);

  const [
    getBasicBootcamps,
    { data: basicBootcamps, loading: basicBootcampsLoading },
  ] = useLazyQuery(GetBootcampsForBannersDocument, {
    variables: {
      bootcampType: EntityName.BasicBootcamp,
    },
    fetchPolicy: "no-cache",
  });

  const [
    getAdvancedBootcamps,
    { data: advancedBootcamps, loading: advancedBootcampsLoading },
  ] = useLazyQuery(GetBootcampsForBannersDocument, {
    variables: {
      bootcampType: EntityName.AdvancedBootcamp,
    },
    fetchPolicy: "no-cache",
  });

  const [
    getMastermindBootcamps,
    { data: mastermindBootcamps, loading: mastermindBootcampsLoading },
  ] = useLazyQuery(GetBootcampsForBannersDocument, {
    variables: {
      bootcampType: EntityName.MastermindBootcamp,
    },
    fetchPolicy: "no-cache",
  });

  const fetchBootcamps = () => {
    getBasicBootcamps();
    getAdvancedBootcamps();
    getMastermindBootcamps();
  };

  const sortBootcampsByStartDate = (bootcamps: BootcampBannerEntry[]) =>
    bootcamps.sort((a, b) => {
      const dateA = a.release?.startDate ? new Date(a.release.startDate) : null;
      const dateB = b.release?.startDate ? new Date(b.release.startDate) : null;

      if (dateA && dateB) {
        return dateA.getTime() - dateB.getTime();
      }
      return dateA ? -1 : dateB ? 1 : 0;
    });

  const basicCompleted =
    basicBootcamps?.getBootcampsForBanners?.completedBanner ?? [];
  const basicCTA = basicBootcamps?.getBootcampsForBanners?.ctaBanner ?? [];
  const basicMyBootcamps =
    basicBootcamps?.getBootcampsForBanners?.myBootcampsBanner ?? [];

  const advancedCompleted =
    advancedBootcamps?.getBootcampsForBanners?.completedBanner ?? [];
  const advancedCTA =
    advancedBootcamps?.getBootcampsForBanners?.ctaBanner ?? [];
  const advancedMyBootcamps =
    advancedBootcamps?.getBootcampsForBanners?.myBootcampsBanner ?? [];

  const mastermindCompleted =
    mastermindBootcamps?.getBootcampsForBanners?.completedBanner ?? [];
  const mastermindCTA =
    mastermindBootcamps?.getBootcampsForBanners?.ctaBanner ?? [];
  const mastermindMyBootcamps =
    mastermindBootcamps?.getBootcampsForBanners?.myBootcampsBanner ?? [];

  const combinedBootcamps = {
    completed: sortBootcampsByStartDate([
      ...basicCompleted,
      ...advancedCompleted,
      ...mastermindCompleted,
    ]).filter((bootcamp) => isAdmin || bootcamp.parent?.status === "PUBLISHED"),
    cta: sortBootcampsByStartDate([
      ...basicCTA,
      ...advancedCTA,
      ...mastermindCTA,
    ]).filter((bootcamp) => isAdmin || bootcamp.parent?.status === "PUBLISHED"),
    myBootcamps: sortBootcampsByStartDate([
      ...basicMyBootcamps,
      ...advancedMyBootcamps,
      ...mastermindMyBootcamps,
    ]).filter((bootcamp) => isAdmin || bootcamp.parent?.status === "PUBLISHED"),
  };
  useEffect(() => {
    fetchBootcamps();
  }, []);
  return {
    data: combinedBootcamps,
    isDataLoading:
      basicBootcampsLoading ||
      advancedBootcampsLoading ||
      mastermindBootcampsLoading,
    refetchBootcamps: fetchBootcamps,
  };
};
