import { IconButton } from "@chakra-ui/react";

import { Ico } from "@/assets/icons";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import type { EditButtonProps } from "./types";

const EditButton = ({
  "aria-label": ariaLabel = "edit",
  icon = <Ico.PenToSquare />,
  onClick,
  roles = [UserRole.Admin, UserRole.Editor],
  ...restProps
}: EditButtonProps): JSX.Element | null => {
  const { hasRoles, isEditModeEnabled } = useUser();
  const canEdit = isEditModeEnabled && hasRoles(roles);

  return onClick !== undefined && canEdit ? (
    <IconButton
      _hover={{ bgColor: "primary.400", color: "white" }}
      aria-label={ariaLabel}
      icon={icon}
      onClick={onClick}
      size={"sm"}
      variant={"ghost"}
      {...restProps}
    />
  ) : null;
};

export { EditButton };
